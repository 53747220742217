import { IconButton, Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { useResults } from "../landing/resultsContext.jsx";
import { useNavigate } from 'react-router-dom'; // Import useHistory
import SearchIcon from '@mui/icons-material/Search';
import InputBase from "@mui/material/InputBase";
import { GridToolbar } from "@mui/x-data-grid";


const SWOT_Bilibili = () => {
    const { psychology } = useResults();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [analysisOption, setAnalysisOption] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [tempQuery, setTempQuery] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        page: 1,
        pageSize: 100,
    });

    const navigate = useNavigate(); // For navigation
    let filteredResults = [];



    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            fetch('http://127.0.0.1:8001/get_like_times_and_message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ bvid: searchQuery }),
            })
                .then(response => response.json())
                .then(data => {
                    const filteredResults = data.data.map(result => ({
                        id: result.rpid,
                        like_times: result.like_times,
                        message: result.message,
                    }));
                    // console.log("filteredResults:", filteredResults);
                    localStorage.setItem('bilibili', JSON.stringify(filteredResults));
                    setRows(filteredResults);
                    setRowCount(filteredResults.length);
                    setLoading(false);
                }).catch(error => {
                    console.error('There was an error!', error);
                    setLoading(false);
                });
        };

        if (searchQuery) {
            fetchData();
        } else {
            const storedResults = localStorage.getItem('bilibili');
            if (storedResults) {
                const parsedResults = JSON.parse(storedResults);
                setRows(parsedResults);
                setRowCount(parsedResults.length);
            }
        }
    }, [searchQuery]);

    const handleSearchInputChange = (event) => {
        setTempQuery(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchQuery(event.target.value);
            localStorage.setItem('searchQuery', event.target.value);
        }
    };

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "like_times", headerName: "Like Times" },
        { field: "message", headerName: "Message", width: 500 },
    ];

    return (
        <Box m="20px">
            <Header title="SWOT Bilibili" subtitle="Related Comments" />

            <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
            >
                <InputBase
                    sx={{ ml: 2, flex: 1 }}
                    placeholder="Search"
                    value={tempQuery}
                    onChange={handleSearchInputChange}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={() => setSearchQuery(searchQuery)}>
                    <SearchIcon />
                </IconButton>
            </Box>

            {rows ? (
                <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        checkboxSelection
                        rows={rows}
                        columns={columns}
                        getRowHeight={() => 'auto'}
                        pagination
                        paginationMode="server"
                        pageSizeOptions={[25, 50, 100]}
                        rowCount={rowCount}
                        loading={loading}
                        page={paginationModel.page}
                        pageSize={paginationModel.pageSize}
                        onPageSizeChange={(newPageSize) => setPaginationModel(prev => ({ ...prev, pageSize: newPageSize }))}
                        onPageChange={(newPage) => setPaginationModel(prev => ({ ...prev, page: newPage }))}
                        getRowId={(row) => row.id} // Specify custom ID using getRowId
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: { sx: { mt: -6 } } // Adds margin bottom to the toolbar
                        }}
                    />
                </Box>
            ) : (
                <Typography variant="h6" align="center" mt={4}>
                    Please enter a search query to display results.
                </Typography>
            )}
        </Box>
    );
};

export default SWOT_Bilibili;
